import React, { useState, useContext } from 'react';

import { Alert, Grid, TextField, Button } from '@mui/material';

import axios from 'axios';

import AdminContext from '../contexts/admin-context';

function LoginPage(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [failedLogin, setFailedLogin] = useState(false);
  const { setAdmin } = useContext(AdminContext);

  function onClickLogin(event) {
    event.preventDefault();
    axios
      .post(process.env.REACT_APP_URI + '/authenticate/', {
        login: username,
        password: password,
      })
      .then((response) => {
        console.log(response);
        localStorage.setItem(
          'accessToken',
          'Bearer ' + response.data.accessToken
        );
        setAdmin('true');
      })
      .catch((error) => {
        console.log(error);
        if (error.code === 'ERR_NETWORK') {
          console.log('NETWORK ERROR');
          //setLoading(false);
        }
        if (error.response.status === 401) {
          setFailedLogin(true);
          console.log('FAILED LOGIN');
        }
      });
  }

  function FailedLoginMessage() {
    if (failedLogin)
      return (
        <Alert severity='error'>Fel användarnamn och/eller lösenord!</Alert>
      );
    return null;
  }

  return (
    <Grid container justifyContent='center' p={2}>
      <Grid container maxWidth='sm'>
        <Grid item mb={2} mt={2} xs={12}>
          <h1>Admin panel</h1>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={onClickLogin}>
            <TextField
              required
              type='email'
              margin='dense'
              fullWidth
              label='Mejladress'
              id='email'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              required
              type='password'
              margin='dense'
              fullWidth
              label='********'
              id='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Grid mt={2} item>
              <Button p={1} color='success' variant='contained' type='submit'>
                Logga in
              </Button>
            </Grid>
          </form>
        </Grid>
        <Grid pt={2} item>
          <FailedLoginMessage />
        </Grid>
      </Grid>
    </Grid>
  );
}
export default LoginPage;
