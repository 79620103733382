import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

function AddJournalPage(props) {
  let { patientId, journalId } = useParams();
  const [date, setDate] = useState('');
  const [batch, setBatch] = useState('');
  const [medicalBackground, setMedicalBackground] = useState('');
  const [pregnant, setPregnant] = useState('');
  const [allergies, setAllergies] = useState('');
  const [previousTreatments, setPreviousTreatments] = useState('');
  const [currentTreatments, setCurrentTreatments] = useState('');

  let navigate = useNavigate();

  useEffect(() => {
    if (journalId) {
      console.log('fetch journal!');
      axios
        .get(
          process.env.REACT_APP_URI +
            '/admin/patient/' +
            patientId +
            '/journals/' +
            journalId,
          {
            headers: { Authorization: localStorage.getItem('accessToken') },
          }
        )
        .then((response) => {
          console.log(response);
          let journal = response.data;
          setBatch(journal.batch);
          setDate(journal.date);
          setMedicalBackground(journal.medicalBackground);
          setPregnant(journal.pregnant);
          setAllergies(journal.allergies);
          setPreviousTreatments(journal.previousTreatments);
          setCurrentTreatments(journal.currentTreatments);
        });
    }
  }, [patientId, journalId]);

  function onClickSubmit(e) {
    e.preventDefault();
    axios({
      method: journalId ? 'put' : 'post',
      url: process.env.REACT_APP_URI + '/admin/journal',
      headers: { Authorization: localStorage.getItem('accessToken') },
      data: {
        id: journalId,
        patientId,
        date,
        batch,
        medicalBackground,
        pregnant,
        allergies,
        previousTreatments,
        currentTreatments,
      },
    })
      .then((response) => {
        navigate('/admin/patienter/' + patientId);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        if (error.code === 'ERR_NETWORK') {
          //console.log("NETWORK ERROR");
          //setLoading(false);
        }
      });
  }

  return (
    <div>
      Patientid: {patientId} <br />
      <br />
      <br />
      <form onSubmit={(e) => onClickSubmit(e)}>
        Behandlingsdatum:
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <TextField
              required
              fullWidth
              id='filled-multiline-flexible'
              //label="Datum"
              variant='outlined'
              type='date'
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              id='filled-multiline-flexible'
              label='Batch'
              multiline
              variant='outlined'
              rows={2}
              value={batch}
              onChange={(e) => setBatch(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              fullWidth
              id='filled-multiline-flexible'
              label='Medicinsk bakgrund'
              multiline
              variant='outlined'
              rows={4}
              value={medicalBackground}
              onChange={(e) => setMedicalBackground(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              id='filled-multiline-flexible'
              label='Gravid'
              multiline
              variant='outlined'
              value={pregnant}
              onChange={(e) => setPregnant(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              id='filled-multiline-flexible'
              label='Allergier'
              multiline
              variant='outlined'
              rows={4}
              value={allergies}
              onChange={(e) => setAllergies(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              id='filled-multiline-flexible'
              label='Tidigare behandlingar'
              multiline
              variant='outlined'
              rows={4}
              value={previousTreatments}
              onChange={(e) => setPreviousTreatments(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              id='filled-multiline-flexible'
              label='Denna behandling'
              multiline
              variant='outlined'
              rows={4}
              value={currentTreatments}
              onChange={(e) => setCurrentTreatments(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button type='submit' fullWidth variant='contained'>
              {' '}
              SPARA
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default AddJournalPage;
