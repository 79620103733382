import { Button, Grid, Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function AvailableTimes() {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  let { city } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      axios
        .get(process.env.REACT_APP_URI + "/bookings/city/" + city)
        .then((response) => {
          //console.log(response);

          setBookings(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          if (error.code === "ERR_NETWORK") {
            //console.log("NETWORK ERROR");
            setLoading(false);
          }
        });
    }
  }, [loading, city]);

  function BookingTimes() {
    return (
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{ textAlign: "center", height: "100vh", padding: 1 }}
      >
        {bookings.map((booking, index) => {
          return (
            <Grid item key={index} xs={6}>
              <Paper sx={{ padding: 4 }}>
                <Grid container spacing={1} direction="column">
                  <Grid item>
                    <Typography variant="h6">{booking.date}</Typography>
                  </Grid>{" "}
                  {booking.bookingSlots.map((slot, index) => {
                    return (
                      <Grid item key={index}>
                        <Button
                          onClick={() => {
                            navigate("/boka/" + city + "/" + slot.id);
                          }}
                          variant="contained"
                        >
                          {slot.time}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sx={{ textAlign: "center", paddingBottom: 5 }}>
        <Typography>
          Tider i {city === "malmo" ? "Malmö" : "Stockholm"}
        </Typography>
      </Grid>
      {bookings.length ? <BookingTimes /> : <NoTimesAvailable />}
    </Grid>
  );
}

function NoTimesAvailable() {
  return (
    <div style={{ height: "100vh" }}>
      Det finns tyvärr inga lediga tider just nu.
    </div>
  );
}
export default AvailableTimes;
