import { React, useState, useEffect } from "react";
import { TextField, Grid, Button, Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Divider from "@mui/material/Divider";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import axios from "axios";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

function SummaryBox(props) {
  return (
    <Grid item sx={{ padding: 1 }}>
      <Typography
        sx={{ paddingTop: 4, padding: 1, margin: 1 }}
        variant="overline"
      >
        {props.icon} <b>{props.text}</b>
      </Typography>
      {props.divider && <Divider variant="middle" />}
    </Grid>
  );
}

function Home() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNbr, setPhoneNbr] = useState("");
  const [treatment, setTreatment] = useState(0);
  const [summaryView, setSummaryView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [booking, setBooking] = useState({});
  const [booked, setBooked] = useState(false);
  const [treatments, setTreatments] = useState([]);
  const [treatmentError, setTreatmentError] = useState(false);

  function TreatmentInfo(treatmentId) {
    if (loading || Number(treatmentId) === 0 || treatmentId === undefined)
      return 0;

    let treatment = treatments.find((treatment) => {
      return Number(treatment.id) === Number(treatmentId);
    });

    return treatment.price;
  }
  /*
  Handle only digits
  const handleSSNChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setSSN(value);
  };*/

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPhoneNbr(value);
  };

  const handleTransportChange = (event) => {
    setTreatment(event.target.value);
    if (treatmentError) setTreatmentError(false);
  };

  let { bookingId } = useParams();

  const onClickReserve = (event) => {
    axios
      .post(process.env.REACT_APP_URI + "/bookings/reserve/" + bookingId, {
        name: name,
        email: email,
        treatmentId: treatment,
        phoneNbr: phoneNbr,
      })
      .then((response) => {
        // console.log(response);

        setBooked(true);
      })
      .catch((error) => {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          //console.log("NETWORK ERROR");
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    // console.log(bookingId);
    if (loading) {
      axios
        .get(process.env.REACT_APP_URI + "/bookings/" + bookingId)
        .then((response) => {
          //console.log(response);

          setBooking(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          if (error.code === "ERR_NETWORK") {
            //console.log("NETWORK ERROR");
            setLoading(false);
          }
        });
      axios.get(process.env.REACT_APP_URI + "/treatments").then((response) => {
        // console.log(response.data)
        setTreatments(response.data);
      });
    }
  }, [loading, bookingId]);

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  function onClickNext(e) {
    e.preventDefault();
    if (Number(treatment) === 0) {
      setTreatmentError(true);
    } else {
      setTreatmentError(false);
      setSummaryView(true);
    }
  }

  function onClickPrev() {
    console.log("onClickPrev");
    setSummaryView(false);
  }

  const summaryBox = (
    <>
      <Grid item xs={4} md={6} sx={{ margin: 1, width: "80vw" }}>
        <Paper>
          <ThemeProvider theme={theme}>
            <Typography sx={{ margin: 1, textAlign: "center" }} variant="h6">
              {" "}
              Bokningsöversikt
            </Typography>
            <Grid container sx={{ margin: 1, width: "80vw" }}>
              <Grid item xs={6}>
                <SummaryBox
                  icon={<AccessTimeIcon />}
                  text={booking.time}
                  divider={true}
                />
              </Grid>
              <Grid item xs={6}>
                <SummaryBox
                  icon={<CalendarMonthIcon />}
                  text={booking.date}
                  divider={true}
                />
              </Grid>
            </Grid>
            <SummaryBox
              icon={<LocalOfferIcon />}
              text={TreatmentInfo(treatment) + ":-"}
              divider
            />

            <SummaryBox icon={<PersonIcon />} text={name} divider />

            <SummaryBox icon={<PhoneIcon />} text={phoneNbr} divider />

            <SummaryBox icon={<EmailIcon />} text={email} />
          </ThemeProvider>
        </Paper>
      </Grid>
      <Button variant="outlined" onClick={onClickPrev}>
        TILLBAKA
      </Button>
      <Button variant="contained" color="success" onClick={onClickReserve}>
        BOKA
      </Button>
    </>
  );

  const smallOverview = (
    <Grid item xs={4} md={6} sx={{ margin: 1, width: "80vw" }}>
      <Paper>
        <ThemeProvider theme={theme}>
          <Typography sx={{ margin: 1, textAlign: "center" }} variant="h6">
            {" "}
            Bokningsöversikt
          </Typography>
          <SummaryBox
            icon={<CalendarMonthIcon />}
            text={booking.date}
            divider={true}
          />
          <SummaryBox
            icon={<AccessTimeIcon />}
            text={booking.time}
            divider={true}
          />
          <SummaryBox
            icon={<LocalOfferIcon />}
            text={TreatmentInfo(treatment) + ":-"}
          />
        </ThemeProvider>
      </Paper>
    </Grid>
  );

  const bookingForm = (
    <form onSubmit={onClickNext}>
      <Grid item xs={12}>
        <Typography sx={{ margin: 1, textAlign: "center" }} variant="h6">
          {" "}
          Dina uppgifter
        </Typography>
        <TextField
          error={treatmentError}
          helperText="Var vänlig och välj en behandling"
          required
          margin="dense"
          fullWidth
          label="Välj behandling"
          id="standard-select-currency-native"
          select
          value={treatment}
          onChange={handleTransportChange}
          SelectProps={{
            native: true,
          }}
        >
          <option value={0}> Välj behandling </option>
          {treatments.map((treatment, index) => {
            return (
              <option key={index} value={treatment.id}>
                {treatment.name}
              </option>
            );
          })}
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          margin="dense"
          fullWidth
          label="Fullständigt namn"
          id="fullname"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type="email"
          margin="dense"
          fullWidth
          label="Mejladress"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          type="text"
          inputProps={{ inputMode: "numeric" }}
          margin="dense"
          fullWidth
          label="Telefonnummer"
          id="phoneNbr"
          value={phoneNbr}
          onChange={(e) => handlePhoneChange(e)}
        />
      </Grid>

      <Grid item xs={12}>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Button type="submit" sx={{ mt: 1, mr: 1 }} variant="outlined">
            Nästa
          </Button>
        </div>
      </Grid>
    </form>
  );
  /*
h1
h2
h3
h4
h5
h6
subtitle1
subtitle2 ***
body1
body2 **
button
caption
overline **
  */
  if (booked) {
    return (
      <Grid
        p={2}
        sx={{ backgroundColor: "#fff" }}
        direction="column"
        container
        alignItems="center"
        textAlign="center"
      >
        <Grid item>
          <CheckCircleRoundedIcon color="success" fontSize="large" />
        </Grid>
        <Grid item>
          <Typography variant="h5" component="h1">
            Din bokning är slutförd{" "}
          </Typography>
        </Grid>
        <Grid item pt={2}>
          <Typography sx={{ color: "black" }} variant="subtitle1">
            Din bokning än nu registrerad & en bekräftelse har skickats till din
            epost!
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      xs={12}
      alignContent="center"
      direction="column"
      sx={{ backgroundColor: "white", paddingBottom: 20 }}
    >
      {!summaryView && smallOverview}

      {summaryView ? summaryBox : bookingForm}
    </Grid>
  );
}

export default Home;
