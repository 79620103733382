import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import AvailableTimes from './AvailableTimes';
import MakeBooking from './MakeBooking';
import LoginPage from './admin/LoginPage';
import AdminPage from './admin/';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AdminContext from './contexts/admin-context';
import BookingsPage from './admin/BookingsPage';
import NewBookingPage from './admin/NewBookingPage';
import AdminStartPage from './admin/AdminStartPage';
import BookingClientInfo from './admin/BookingClientInfo';
import PatientsPage from './admin/PatientsPage';
import PatientsJournalPage from './admin/PatientsJournalPage';
import JournalsPage from './admin/JournalsPage';
import AddPatientPage from './admin/AddPatientPage';
import AddJournalPage from './admin/AddJournalPage';
import HomePage from './HomePage';

function App() {
  const [admin, setAdmin] = useState('false');
  const value = { admin, setAdmin };

  useEffect(() => {
    let token = localStorage.getItem('accessToken');
    if (token === null || token === undefined) {
      // console.log("NO TOKEN!");
      setAdmin('false');
    } else {
      axios
        .get(process.env.REACT_APP_URI + '/admin/token/', {
          headers: { Authorization: localStorage.getItem('accessToken') },
        })
        .then((response) => {
          //console.log(response);
          setAdmin('true');
        })
        .catch((error) => {
          localStorage.setItem('accessToken', null);
          setAdmin('false');
          //console.log(error);
          if (error.code === 'ERR_NETWORK') {
            console.log('NETWORK ERROR');
            //setLoading(false);
          }
        });
    }
  }, [admin]);

  return (
    <AdminContext.Provider value={value}>
      <div>
        <Router>
          <Routes>
            <Route path='/' element={<Home />}>
              <Route index element={<HomePage />} />
              <Route path='boka/:city' element={<AvailableTimes />} />
              <Route path='boka/:city/:bookingId' element={<MakeBooking />} />
            </Route>

            {admin === 'true' ? (
              <Route path='admin' element={<AdminPage />}>
                <Route index element={<AdminStartPage />} />
                <Route path='ny-bokning' element={<NewBookingPage />} />{' '}
                {/*A nested route!*/}
                <Route path='bokningar' element={<BookingsPage />} />
                <Route
                  path='bokningar/:bookingId'
                  element={<BookingClientInfo />}
                />
                <Route path='patienter' element={<PatientsJournalPage />}>
                  <Route index element={<PatientsPage />} />
                  <Route path=':patientId' element={<JournalsPage />} />
                  <Route path=':ny-patient' element={<AddPatientPage />} />
                  <Route
                    path=':ny-patient/:patientId'
                    element={<AddPatientPage />}
                  />
                  <Route
                    path=':patientId/ny-journal'
                    element={<AddJournalPage />}
                  />
                  <Route
                    path=':patientId/ny-journal/:journalId'
                    element={<AddJournalPage />}
                  />
                </Route>
              </Route>
            ) : (
              <Route path='admin/*' element={<LoginPage />} />
            )}

            {/*           <Route path="/boka" element={<Booking />} />
          <Route path="/boka/:id" element={<MakeBooking />} />
          <Route path="*" element={<ErrPage />} />*/}
          </Routes>
        </Router>
      </div>
    </AdminContext.Provider>
  );
}

export default App;
