import { Box, Paper, Grid, Button } from "@mui/material";
import logo from "./logo.svg";
import { useNavigate } from "react-router-dom";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/system";
import { Outlet } from "react-router-dom";

let theme = createTheme();
theme = responsiveFontSizes(theme);

function Home() {
  let navigate = useNavigate();
  return (
    <div style={{ backgroundColor: "pink", height: "100vh", width: "100vw" }}>
      <Grid
        container
        justifyContent="center"
        style={{ backgroundColor: "pink" }}
      >
        <a href="/">
          <Box
            justifyContent="center"
            component="img"
            sx={{
              height: 200,
              width: 350,
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
            }}
            alt="Hd Beauty logga"
            src={logo}
          />
        </a>
      </Grid>
      <ThemeProvider theme={theme}>
        <Outlet></Outlet>
      </ThemeProvider>
    </div>
  );
}

export default Home;
