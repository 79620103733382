import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import AdminContext from '../../contexts/admin-context';
import HDBeautyLogo from '../../logo.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { Hidden } from '@mui/material';
import DasaImg from '../../static/images/dasareta.png';
const drawerWidth = 240;

function ResponsiveDrawer(props, { router: Router }) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { setAdmin } = useContext(AdminContext);

  let navItems = [
    { name: 'Startsida', link: '', icon: <HomeOutlinedIcon /> },
    { name: 'Patienter', link: './patienter', icon: <PeopleAltOutlinedIcon /> },

    { name: 'Skapa bokning', link: './ny-bokning', icon: <AddOutlinedIcon /> },
    {
      name: 'Bokningar',
      link: './bokningar',
      icon: <CalendarMonthOutlinedIcon />,
    },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar>
        <Box
          component='img'
          sx={{ width: 150 }}
          alt='HD Beauty logo'
          src={HDBeautyLogo}
        />
      </Toolbar>
      <List>
        {navItems.map((text, index) => (
          <ListItem
            button
            key={text.name}
            component={Link}
            to={text.link}
            onClick={() => setMobileOpen(false)}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon>{text.icon}</ListItemIcon>
              <ListItemText primary={text.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }}>
      <AppBar style={{ background: '#2E3B55' }} position='fixed'>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, outlined: '1' }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((text, index) => (
              <Button
                key={index}
                sx={{ color: '#fff' }}
                component={Link}
                to={text.link}
              >
                {text.name}
              </Button>
            ))}
          </Box>

          <Box
            sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              sx={{ color: 'white' }}
              variant='text'
              onClick={handleClick}
              startIcon={
                <Avatar src={DasaImg} alt='Dasareta' sx={{ padding: '10' }} />
              }
            >
              <Hidden smDown>Dasareta</Hidden>
            </Button>

            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                onClick={() => {
                  localStorage.setItem('accessToken', null);
                  setAdmin(false);
                }}
              >
                Logga ut
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component='nav' aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {/*<AllRoutes admin={props.admin} toggleAdmin={props.toggleAdmin} /> */}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
