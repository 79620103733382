import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function BookingClientInfo(props) {
  const [loading, setLoading] = useState(true);
  const [bookingDetails, setBookingDetails] = useState({});
  let { bookingId } = useParams();
  const navigate = useNavigate();

  function onClickCancel() {
    console.log("cancel");
    axios
      .delete(
        process.env.REACT_APP_URI + "/admin/customerBooking/" + bookingId,
        {
          headers: { Authorization: localStorage.getItem("accessToken") },
        }
      )
      .then((response) => {
        navigate("/admin/bokningar/");
      });
  }

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_URI + "/admin/customer/" + bookingId, {
        headers: { Authorization: localStorage.getItem("accessToken") },
      })
      .then((response) => {
        console.log(response);
        setBookingDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        localStorage.setItem("accessToken", null);
        //console.log(error);
        if (error.code === "ERR_NETWORK") {
          console.log("NETWORK ERROR");
          //setLoading(false);
        }
      });
  }, [bookingId, loading]);

  if (loading) return <div>Loading...</div>;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <b>Namn: </b> {bookingDetails.customer.name}
      </Grid>
      <Grid item xs={12}>
        <b>Tid & Datum: </b>
        {bookingDetails.booking.time}, {bookingDetails.booking.date}
      </Grid>
      <Grid item xs={12}>
        <b>Stad:</b> {bookingDetails.booking.city}
      </Grid>
      <Grid item xs={12}>
        <b>Epost:</b> {bookingDetails.customer.email}
      </Grid>
      <Grid item xs={12}>
        <b>Telefon:</b> {bookingDetails.customer.phone_nbr}
      </Grid>
      <Grid item xs={12}>
        <b> Behandling:</b> {bookingDetails.treatment.name}
      </Grid>
      <Grid item xs={12}>
        <b>Pris:</b> {bookingDetails.treatment.price} kr
      </Grid>
      <Grid item>
        <Button
          sx={{ marginTop: 10 }}
          onClick={onClickCancel}
          variant="contained"
          color="error"
        >
          Avboka
        </Button>
      </Grid>
    </Grid>
  );
}

export default BookingClientInfo;
