import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Chip, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

function BookingStatus(props) {
  return (
    <Chip
      color={props.status === "AVAILABLE" ? "success" : "error"}
      label={props.status}
    />
  );
}

function BookingsPage(props) {
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);

  let navigate = useNavigate();

  function onClickDeleteButton(id) {
    console.log("try and delete " + id);
    axios
      .delete(process.env.REACT_APP_URI + "/bookings/admin/bookings/" + id, {
        //headers: { Authorization: localStorage.getItem("accessToken") },
      })
      .then((response) => {
        console.log(response);
        getBookings();
        //console.log(response);
      })
      .catch((error) => {
        //console.log(error);
        if (error.code === "ERR_NETWORK") {
          console.log("NETWORK ERROR");
          //setLoading(false);
        }
      });
  }

  function getBookings() {
    axios
      .get(process.env.REACT_APP_URI + "/bookings/admin/bookings", {
        //headers: { Authorization: localStorage.getItem("accessToken") },
      })
      .then((response) => {
        console.log(response);
        setBookings(response.data);
        //console.log(response);
      })
      .catch((error) => {
        //console.log(error);
        if (error.code === "ERR_NETWORK") {
          console.log("NETWORK ERROR");
          //setLoading(false);
        }
      });
  }

  useEffect(() => {
    getBookings();
  }, [loading]);

  if (loading) {
    return <div>Här kommer du snart kunna se alla tider...</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ maxWidth: "sm" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell> # Id</TableCell>
            <TableCell align="right">Datum, Tid & Stad</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">KundInfo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings.map((booking, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {booking.id}
              </TableCell>
              <TableCell align="right">
                {booking.date} <br />
                {booking.time} <br />
                {booking.city} <br />
                <IconButton
                  onClick={() => onClickDeleteButton(booking.id)}
                  aria-label="delete"
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </TableCell>

              <TableCell align="right">
                <BookingStatus status={booking.status} />
              </TableCell>
              <TableCell align="right">
                {booking.status === "AVAILABLE" ? (
                  "-"
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate("/admin/bokningar/" + booking.id);
                    }}
                  >
                    Kundinfo
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BookingsPage;
