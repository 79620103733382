import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import {
  Fab,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
  Divider,
  ListItem,
  ListItemText,
  List,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
function PatientHeader(props) {
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState({});

  let { patientId } = useParams();
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_URI + '/admin/patients/' + patientId, {
        headers: { Authorization: localStorage.getItem('accessToken') },
      })
      .then((response) => {
        setPatient(response.data);
        setLoading(false);
      })
      .catch((error) => {
        //console.log(error);
        if (error.code === 'ERR_NETWORK') {
          console.log('NETWORK ERROR');
          //setLoading(false);
        }
      });
  }, [loading, patientId]);
  if (loading) return <div>Laddar....</div>;
  return (
    <Paper sx={{ padding: 1, margin: 4 }}>
      <List>
        <ListItem>
          <ListItemText align='left'>Namn:</ListItemText>
          <ListItemText align='right'>
            {' '}
            <strong>{patient.name}</strong>
          </ListItemText>
        </ListItem>
        <Divider variant='middle' />

        <ListItem>
          <ListItemText align='left'>Personnummer:</ListItemText>
          <ListItemText align='right'>
            {' '}
            <strong>{patient.ssn}</strong>
          </ListItemText>
        </ListItem>
        <Divider variant='middle' />

        <ListItem>
          <ListItemText align='left'>Adress:</ListItemText>
          <ListItemText align='right'>
            {' '}
            <strong>{patient.address}</strong>
          </ListItemText>
        </ListItem>
        <Divider variant='middle' />

        <ListItem>
          <ListItemText align='left'>Telefonnummer:</ListItemText>
          <ListItemText align='right'>
            {' '}
            <strong>{patient.phoneNbr}</strong>
          </ListItemText>
        </ListItem>
        <Divider variant='middle' />

        <ListItem>
          <ListItemText align='left'>Epost:</ListItemText>
          <ListItemText align='right'>
            {' '}
            <strong>{patient.email}</strong>
          </ListItemText>
        </ListItem>
      </List>
    </Paper>
  );
}

function PatientHistory(props) {
  const [loading, setLoading] = useState(true);
  const [journals, setJournals] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [fileList, setFileList] = useState();
  const navigate = useNavigate();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let { patientId } = useParams();
  function uploadImage(journalId, status) {
    var formData = new FormData();

    for (let i = 0; i < fileList.length; i++) {
      formData.append('files', fileList[i]);
    }
    formData.append('status', status);
    axios
      .post(
        process.env.REACT_APP_URI + '/admin/uploadMultipleImages/' + journalId,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: localStorage.getItem('accessToken'),
          },
        }
      )
      .then((response) => {
        setFileList([]);
        fetchData();
      });
  }

  const fetchData = useCallback(() => {
    axios
      .get(
        process.env.REACT_APP_URI +
          '/admin/patient/' +
          patientId +
          '/journals/',
        {
          headers: { Authorization: localStorage.getItem('accessToken') },
        }
      )
      .then((response) => {
        setJournals(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          console.log('NETWORK ERROR');
        }
      });
  }, [patientId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  if (loading) return <div> laddar...</div>;

  return (
    <div>
      {journals.map((journal, index) => {
        return (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                {journal.date}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                {journal.currentTreatments}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography pb={2}>
                Redigera journal
                <IconButton
                  aria-label='delete'
                  onClick={() =>
                    navigate(
                      '/admin/patienter/' +
                        patientId +
                        '/ny-journal/' +
                        journal.id
                    )
                  }
                >
                  <EditIcon />
                </IconButton>
              </Typography>
              <Typography>
                <b>Batch:</b>
                <br /> {journal.batch} <br />
                <br />
                <b>Medicinsk bakgrund:</b>
                <br /> {journal.medicalBackground} <br />
                <br />
                <b>Gravid/Ammar:</b>
                <br /> {journal.pregnant} <br />
                <br />
                <b>Allergier:</b>
                <br /> {journal.allergies} <br />
                <br />
                <b>Tidigare behandlingar:</b>
                <br /> {journal.previousTreatments} <br />
                <br />
                <b>Denna behandling:</b>
                <br /> {journal.currentTreatments} <br />
                <br />
                <b>Före bilder</b>
                <br /> Lite bilder här. <br />
                <br />
                <Images path={journal.beforeImages} />
                <br /> <br />
                <input
                  type='file'
                  //value={file}
                  onChange={(e) => setFileList(e.target.files)}
                  id='avatar'
                  name='avatar'
                  accept='image/png, image/jpeg'
                  multiple
                ></input>
                <br />
                <br />
                {fileList &&
                  Array.from(fileList).map((f) => {
                    const src = URL.createObjectURL(f);
                    return (
                      <img
                        key={f.name}
                        src={src}
                        alt='error'
                        width='100'
                        height='100'
                      />
                    );
                  })}
                <Button
                  onClick={() => uploadImage(journal.id, 'BEFORE')}
                  variant='contained'
                >
                  {' '}
                  SPARA INNAN{' '}
                </Button>
                <br />
                <br />
                <b>Efter bilder</b>
                <Images path={journal.afterImages} />
                <br /> Lite bilder här. <br />
                <br />
                <input
                  type='file'
                  //value={file}
                  onChange={(e) => setFileList(e.target.files)}
                  id='avatar'
                  name='avatar'
                  accept='image/png, image/jpeg'
                  multiple
                ></input>
                <br />
                <br />
                {fileList &&
                  Array.from(fileList).map((f) => {
                    const src = URL.createObjectURL(f);
                    return (
                      <img
                        key={f.name}
                        src={src}
                        alt='error'
                        width='100'
                        height='100'
                      />
                    );
                  })}
                <Button
                  onClick={() => uploadImage(journal.id, 'AFTER')}
                  variant='contained'
                >
                  {' '}
                  SPARA Efter{' '}
                </Button>
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

function Images(props) {
  return props.path.map((image, index) => {
    const path = process.env.REACT_APP_URI + '/images/' + image.imageName;

    return (
      <li key={index}>
        {index + 1}. <a href={path}> {image.imageName}</a> <br />
      </li>
    );
  });
}

function JournalsPage(props) {
  let navigate = useNavigate();
  let { patientId } = useParams();

  return (
    <div>
      Ändra information
      <IconButton
        aria-label='delete'
        onClick={() => navigate('/admin/patienter/ny-patient/' + patientId)}
      >
        <EditIcon />
      </IconButton>
      <PatientHeader />
      <PatientHistory />
      <Fab
        sx={{
          position: 'fixed',
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
        }}
        color='secondary'
        aria-label='add'
      >
        <AddIcon
          onClick={() =>
            navigate('/admin/patienter/' + patientId + '/ny-journal')
          }
        />
      </Fab>
    </div>
  );
}

export default JournalsPage;
