import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Grid } from '@mui/material';

import axios from 'axios';
function AddPatientPage(props) {
  const [name, setName] = useState('');
  const [ssn, setSsn] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNbr, setPhoneNbr] = useState('');
  const [email, setEmail] = useState('');
  let navigate = useNavigate();
  let { patientId } = useParams();

  useEffect(() => {
    if (patientId) {
      console.log('fetch patient!');
      axios
        .get(process.env.REACT_APP_URI + '/admin/patients/' + patientId, {
          headers: { Authorization: localStorage.getItem('accessToken') },
        })
        .then((response) => {
          let patient = response.data;
          setName(patient.name);
          setPhoneNbr(patient.phoneNbr);
          setSsn(patient.ssn);
          setAddress(patient.address);
          setEmail(patient.email);
        });
    }
  }, [patientId]);

  function onSubmit() {
    console.log('SUBMITTING');
    console.log(name);
    if (patientId) {
    }
    axios({
      method: patientId ? 'put' : 'post',
      url: process.env.REACT_APP_URI + '/admin/patient',
      headers: { Authorization: localStorage.getItem('accessToken') },
      data: {
        id: patientId,
        name,
        ssn,
        address,
        phoneNbr,
        email,
      },
    })
      .then((response) => {
        console.log(response);
        navigate('/admin/patienter');
      })
      .catch((error) => {
        console.log(error);
        if (error.code === 'ERR_NETWORK') {
          //console.log("NETWORK ERROR");
          //setLoading(false);
        }
      });
  }

  return (
    <div>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <TextField
            fullWidth
            id='outlined-basic'
            label='Namn'
            variant='outlined'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id='outlined-basic'
            label='Personnummer'
            variant='outlined'
            value={ssn}
            onChange={(e) => setSsn(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id='outlined-basic'
            label='Address'
            variant='outlined'
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id='outlined-basic'
            label='Telefonnummer'
            variant='outlined'
            value={phoneNbr}
            onChange={(e) => setPhoneNbr(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id='outlined-basic'
            label='Epost'
            variant='outlined'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button fullWidth onClick={() => onSubmit()} variant='contained'>
            Spara
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddPatientPage;
