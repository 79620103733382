import AddIcon from '@mui/icons-material/Add';
import {
  Fab,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

function PatientsPage(props) {
  const [loading, setLoading] = useState(true);
  const [patients, setPatients] = useState([]);

  let navigate = useNavigate();
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_URI + '/admin/patients/', {
        headers: { Authorization: localStorage.getItem('accessToken') },
      })
      .then((response) => {
        setPatients(response.data);
        setLoading(false);
      })
      .catch((error) => {
        //console.log(error);
        if (error.code === 'ERR_NETWORK') {
          console.log('NETWORK ERROR');
          //setLoading(false);
        }
      });
  }, [loading]);

  if (loading) return <div>laddar...</div>;

  return (
    <>
      <Grid pl={4} mt={3} container spacing={4}>
        <PatientsTable patients={patients} />
        <Fab
          sx={{
            position: 'fixed',
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
          }}
          color='secondary'
          aria-label='add'
        >
          <AddIcon onClick={() => navigate('ny-patient')} />
        </Fab>
        <Outlet></Outlet>
      </Grid>
    </>
  );
}

function PatientsTable(props) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2E3B55',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.action.hover,
      backgroundColor: 'darkgrey',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  let navigate = useNavigate();

  if (!props.patients) {
    return null;
  }
  return (
    <TableContainer component={Paper}>
      <Table aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>PATIENTNAMN</StyledTableCell>
            <StyledTableCell align='right'>E-MAIL</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.patients.map((patient, index) => (
            <StyledTableRow
              hover
              key={patient.name}
              onClick={() => navigate('./' + patient.id)}
            >
              <StyledTableCell component='th' scope='row' >

                <p className="PatientName"> {patient.name}</p>
              </StyledTableCell>

              <StyledTableCell align='right'>{patient.email}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PatientsPage;
