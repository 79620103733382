import React from "react";
import {
  Alert,
  AlertTitle,
  FormControl,
  Button,
  Box,
  Container,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import axios from "axios";

function NewBookingPage() {
  const [datetime, setDateTime] = React.useState(dayjs());
  const [response, setResponse] = React.useState("");
  const [city, setCity] = React.useState("STOCKHOLM");

  var date = datetime.format("YYYY/MM/DD");
  var time = datetime.format("HH:mm");

  const onCityChange = (event) => {
    setCity(event.target.value);
  };

  const onClickNewTime = (event) => {
    console.log("clicked");
    axios
      .post(
        process.env.REACT_APP_URI + "/admin/newtime",
        {
          city,
          date,
          time,
        },
        {
          headers: { Authorization: localStorage.getItem("accessToken") },
        }
      )
      .then((response) => {
        console.log(response);
        setResponse(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          //console.log("NETWORK ERROR");
          //setLoading(false);
        }
      });
  };

  function SuccessMessage() {
    if (response !== "") {
      return (
        <Alert severity="success">
          <AlertTitle>Ny bokning</AlertTitle>
          {response}
        </Alert>
      );
    }
  }

  return (
    <Container maxWidth="sm">
      <Box pt={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <MobileDateTimePicker
              ampmInClock={false}
              ampm={false}
              label="For mobile"
              value={datetime}
              onChange={(newValue) => {
                setDateTime(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>

        <FormControl fullWidth>
          <TextField
            margin="dense"
            fullWidth
            required
            label="Välj transport"
            id="standard-select-currency-native"
            select
            value={city}
            onChange={onCityChange}
            SelectProps={{
              native: true,
            }}
          >
            <option disabled value={""} />
            <option value={"MALMO"}> Malmö </option>
            <option value={"STOCKHOLM"}>STOCKHOLM</option>
          </TextField>
        </FormControl>
      </Box>
      Datum: {date} <br />
      Tid: {time} <br />
      Stad: {city} <br />
      <br />
      <Button variant="contained" color="primary" onClick={onClickNewTime}>
        Lägg till tid
      </Button>
      <br />
      <br />
      <br />
      <SuccessMessage />
      <br />
    </Container>
  );
}
export default NewBookingPage;
