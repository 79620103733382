import React from "react";
import { Grid, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import TopDrawer from "./menu/TopDrawer";

function AdminPage() {
  return (
    <>
      <TopDrawer></TopDrawer>
      <Container sx={{ paddingBottom: 10, paddingTop: 0 }} maxWidth="sm">
        <Grid pt={3} container>
          <Grid pt={2} xs={12} item>
            <Outlet></Outlet>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
export default AdminPage;
