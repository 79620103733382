import { Paper, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/system";

function HomePage() {
  let navigate = useNavigate();
  return (
    <Container maxWidth="sm">
      <Paper sx={{ padding: 5 }}>
        <Grid
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h3" component="h1">
              Tidsbokning
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="h6">Välj stad</Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("/boka/malmo");
              }}
            >
              Malmö
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                navigate("/boka/stockholm");
              }}
            >
              Stockholm
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default HomePage;
